// Notices
import {$on} from '../helpers'

$on(':root', ['.Notice > .Button', '.Notice > .Notice-close > i'], 'click', function(ev) {
  ev.preventDefault()
  // @ts-ignore
  var container = ev.target.closest('.Notice')
  // .vdom is to filter vdom library internal structure.
  // if there is a .Notice showing up from React
  // it can use same html structure as in django-templates
  // with a Portal, avoiding to reimplement the same feature twice in
  // two separate places.
  // There is a better way to do this from React  but that's
  // quite time-consuming so to avoid throwing js errors until
  // the proper approach is implemented in such a React app
  // customjs helper functions can simply avoid .vdom classes
  // In most cases I assume this is even more performant than
  // what the officially suggested React solution is
  if (container && container.matches(':not(.vdom)')) container.parentNode.removeChild(container) // ChildNode.remove() is not available in ie11
  return false
})
