import {$on, $nl2arr} from '../helpers'
/**
 * Tabs
 * Functionality to accompany @styles/molecules/Tabs.
 * Handles switching tabs in an accessible way following the example in:
 * https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
 */

$on(':root', ['.Tabs [role=tab]'], 'click', function(ev) {
  var tabHeaderEl = ev.target.parentNode
  var tabs = $nl2arr(tabHeaderEl.children)
  var activeIndex = tabs.indexOf(ev.target)

  // Tabs
  for (var i = 0; i < tabs.length; i++) {
    let tab = tabs[i]
    let panelId = tab.getAttribute('aria-controls')
    let panel = document.getElementById(panelId)

    // Set selected tab and panel
    if (i === activeIndex) {
      tab.setAttribute('aria-selected', 'true')
      panel.removeAttribute('hidden')
    } else {
      tab.setAttribute('aria-selected', 'false')
      panel.setAttribute('hidden', true)
    }
  }
})
