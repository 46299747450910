/**
 * onClick handler for links annotated with 'scroll-to-el' class.
 * Scrolls to the element with id given in the href of the link.
 */
import scrollToEl from 'scroll-to-element'
import {$on} from '../helpers'
function handleScrollToEl(ev) {
  ev.preventDefault()
  var targetEl = document.querySelector(ev.target.getAttribute('href'))
  scrollToEl(targetEl, {
    ease: 'out-quart',
    duration: 800
  })
}

// Scroll to Element
$on('body', ['.scroll-to-el'], 'mousedown', handleScrollToEl)
