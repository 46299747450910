import * as Sentry from '@sentry/browser'

const SENTRY_DSN = 'https://be272bb1d82b4bd1b7f83b6fd73ab284@sentry.io/1446414'

// process.env provided by Webpack DefinePlugin
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN
  })
}
