import 'get-own-property-symbols'
import 'airbnb-browser-shims'

import './heading'
import './modal'
import './notification'
import './scrolling'
import './tabs'
import './lazy_sizes'
import './sticky'
import './sentry'

// Import the SASS for the Content (or Marketing) pages
// to avoid having to build sass in the command line
import './base.global.scss'
import {ready, $subscribe, $$$} from '../helpers'

// The matches polyfill doesn't apply when only the name is different ...
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
}

// Make every form's submit button become disabled once clicked.
// NOTE: This means the submit input's value will not be submitted in the POST.
// This is usually not a problem, but if you need that value, apply the no-disable-on-submit class.
ready(function() {
  $subscribe('form', 'submit', function(ev) {
    $$$(
      'input[type="submit"]:not(.no-disable-on-submit), button[type="submit"]:not(.no-disable-on-submit)',
      function(el) {
        el.setAttribute('disabled', 'disabled')
      },
      // @ts-ignore EventTarget is not an Element.
      ev.target
    )
  })
})

console.log('global app has run')
