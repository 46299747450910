import {$,$$$,$subscribe, $on, showHideElement, transitionOff, transitionOn} from '../helpers'
$on(':root', ['.showModal'], 'click', function(ev) {
  ev.preventDefault()
  var modal = $('.modal')
  var targetEl = ev.target
  if (targetEl && targetEl.dataset.modalId) {
    $$$(
      '.modal-content',
      function(el) {
        showHideElement(el, el.dataset.modalTarget === targetEl.dataset.modalId)
      },
      modal
    )
  }
  transitionOff(modal, 366, 'is-hidden')
  transitionOn(modal, 366, 'is-active')
})
$on(':root', ['.close-modal', '.close-modal i.fas'], 'click', function(ev) {
  ev.preventDefault()
  var modal = document.querySelector('.modal:not(.vdom)')
  if (modal) {
    transitionOn(modal, 366, 'is-hidden')
    transitionOff(modal, 366, 'is-active')
  }
})
