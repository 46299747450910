import {ready, $$$} from '../helpers'

ready(function() {
  // Configure the hamburger to show its nav when clicked.

  function $(selector, parent) {
    return (parent || document).querySelector(selector)
  }

  var header = $('.Header')
  if (!header) return

  var burger = $('.Header-nav-burger')
  $$$('.Header-nav-overlay, .Header-nav-burger', function(el) {
    el.addEventListener('click', function(ev) {
      header.classList.toggle('nav-is-open')
    })
  })
})
